<template>
  <b-form-group
    label="Renk"
    label-for="id_com_color"
  >
    <validation-provider
      #default="{ errors }"
      name="Renk"
      rules="required"
    >
      <v-select
        id="id_com_color"
        v-model="dataItem.id_com_color"
        label="title"
        :reduce="item => item.id"
        :options="colors"
        placeholder="Renk"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Colors',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
    colors() {
      return this.$store.getters['colors/getColors']
    },
  },
  created() {
    localize('tr')
    this.getColors()
  },
  methods: {
    getColors() {
      this.$store.dispatch('colors/colorsList', {
        select: ['com_color.id AS id', 'com_color.title AS title'],
      })
    },
  },
}
</script>
