<template>
  <b-form-group
    label="Vites Tipi"
    label-for="id_com_gear"
  >
    <validation-provider
      #default="{ errors }"
      name="Vites Tipi"
      rules="required"
    >
      <v-select
        id="id_com_gear"
        v-model="dataItem.id_com_gear"
        label="title"
        :reduce="item => item.id"
        :options="fuels"
        placeholder="Vites Tipi"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Gears',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
    fuels() {
      return this.$store.getters['gears/getGears']
    },
  },
  created() {
    localize('tr')
    this.getColors()
  },
  methods: {
    getColors() {
      this.$store.dispatch('gears/gearsList', {
        select: ['com_gear.id AS id', 'com_gear.title AS title'],
      })
    },
  },
}
</script>
