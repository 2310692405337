<template>
  <b-form-group
    label="Yakıt Tipi"
    label-for="id_com_fuel"
  >
    <validation-provider
      #default="{ errors }"
      name="Yakıt Tipi"
      rules="required"
    >
      <v-select
        id="id_com_fuel"
        v-model="dataItem.id_com_fuel"
        label="title"
        :reduce="item => item.id"
        :options="fuels"
        placeholder="Yakıt Tipi"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Fuels',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
    fuels() {
      return this.$store.getters['fuels/getFuels']
    },
  },
  created() {
    localize('tr')
    this.getColors()
  },
  methods: {
    getColors() {
      this.$store.dispatch('fuels/fuelsList', {
        select: ['com_fuel.id AS id', 'com_fuel.title AS title'],
      })
    },
  },
}
</script>
