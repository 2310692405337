<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="VehicleIcon" />
        <span>Araç Bilgisi</span>
      </template>
      <validation-observer ref="simpleRules">
        <item-form />
        <save-button
          :save-action="submitForm"
          :is-disabled="submitStatus"
        />
      </validation-observer>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Sözleşmeler</span>
      </template>
      <contracts />
    </b-tab>
    <b-tab v-if="['12','13','15'].includes(userData.id_com_user_type)">
      <template #title>
        <feather-icon icon="BookIcon" />
        <span>Masraflar</span>
      </template>
      <expenses />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ArchiveIcon" />
        <span>Dokümanlar</span>
      </template>
      <documents />
    </b-tab>
  </b-tabs>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ItemForm from '@/views/Rental/Stocks/ItemForm.vue'
import Contracts from '@/views/Rental/Stocks/Tabs/Contracts.vue'
import Expenses from '@/views/Rental/Stocks/Tabs/Expenses.vue'
import Documents from '@/views/Rental/Stocks/Tabs/Documents.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    ItemForm,
    Contracts,
    Expenses,
    Documents,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
    saveData() {
      return this.$store.getters['rentalStocks/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('rentalStocks/RESET_DATA_ITEM')
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('rentalStocks/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.id_com_swap_brand) {
            this.$store.dispatch('swapmodels/swapmodelsList', {
              select: [
                'com_swap_model.id AS id',
                'com_swap_model.title AS title',
              ],
              where: {
                'com_swap_model.id_com_swap_brand': res.id_com_swap_brand,
              },
            })
          }
          if (res.arackodu) {
            this.$store.dispatch('rentalExpenses/getDataList', res.arackodu)
          }
        })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('rentalStocks/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
