<template>
  <b-form-group
    label="Arvento No"
    label-for="arvento_no"
  >
    <b-form-input
      id="arvento_no"
      v-model="dataItem.arvento_no"
      placeholder="Arvento No"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'ArventoNo',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
  },
}
</script>
