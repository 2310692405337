<template>
  <b-row class="mb-2 mt-1">
    <b-col cols="auto">
      <b-form-checkbox
        id="kabis"
        v-model="dataItem.kabis"
      >
        Kabis
      </b-form-checkbox>
    </b-col>
    <b-col cols="auto">
      <b-form-checkbox
        id="arvento"
        v-model="dataItem.arvento"
      >
        Arvento
      </b-form-checkbox>
    </b-col>
    <b-col cols="auto">
      <b-form-checkbox
        id="kredi"
        v-model="dataItem.kredi"
      >
        Kredi
      </b-form-checkbox>
    </b-col>
    <b-col cols="auto">
      <b-form-checkbox
        id="amortisman"
        v-model="dataItem.amortisman"
      >
        Amortisman
      </b-form-checkbox>
    </b-col>
    <b-col cols="auto">
      <b-form-checkbox
        id="tasitmatik"
        v-model="dataItem.tasitmatik"
      >
        Taşıtmatik
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'Checkboxes',
  components: {
    BRow, BCol, BFormCheckbox,
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
  },
}
</script>
